.nav-link {
    color: #fdf0d5 !important;
}
 
.brand-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.brand-container h3 {
    margin: auto;
    color: #fdf0d5;
    margin-left: 10px;
}