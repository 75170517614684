#card-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border: 0;
    border-radius: 30px;
    background-color: #003049;
    min-height: 400px;
  }

  .project-title {
    font-weight: bold;
  }